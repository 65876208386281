const documentContainer = document.createElement('template');

documentContainer.innerHTML = `<iron-iconset-svg name="landing" size="24">
<svg><defs>
<g id="google">
    <path fill="#4285F4" d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"></path>
    <path fill="#34A853" d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"></path>
    <path fill="#FBBC05" d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"></path>
    <path fill="#EA4335" d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"></path>
    <path fill="none" d="M1 1h22v22H1z"></path>
</g>
<g id="github" fill="#000" viewBox="0 0 16 16"><path d="M8 0C3.58 0 0 3.582 0 8c0 3.535 2.292 6.533 5.47 7.59.4.075.547-.172.547-.385 0-.19-.007-.693-.01-1.36-2.226.483-2.695-1.073-2.695-1.073-.364-.924-.89-1.17-.89-1.17-.725-.496.056-.486.056-.486.803.056 1.225.824 1.225.824.714 1.223 1.873.87 2.33.665.072-.517.278-.87.507-1.07-1.777-.2-3.644-.888-3.644-3.953 0-.873.31-1.587.823-2.147-.083-.202-.358-1.015.077-2.117 0 0 .672-.215 2.2.82.638-.178 1.323-.266 2.003-.27.68.004 1.364.092 2.003.27 1.527-1.035 2.198-.82 2.198-.82.437 1.102.163 1.915.08 2.117.513.56.823 1.274.823 2.147 0 3.073-1.87 3.75-3.653 3.947.287.246.543.735.543 1.48 0 1.07-.01 1.933-.01 2.195 0 .215.144.463.55.385C13.71 14.53 16 11.534 16 8c0-4.418-3.582-8-8-8"></path></g>
<g id="ms" viewBox="70 70 90 90">
    <path d="M106.214 106.214H71.996V71.996h34.218z" fill="#f25022"></path>
    <path d="M143.993 106.214h-34.218V71.996h34.218z" fill="#7fba00"></path>
    <path d="M106.214 143.993H71.996v-34.218h34.218z" fill="#00a4ef"></path>
    <path d="M143.993 143.993h-34.218v-34.218h34.218z" fill="#ffb900"></path>
</g>
<g id="cilogon" viewBox="2.0 10.0 160.0 156.0" fill="none" stroke="none" stroke-linecap="square"
stroke-miterlimit="10" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
    <path fill="#ffffff" fill-opacity="100.0" d="m0 0l800.0 0l0 600.0l-800.0 0z" fill-rule="evenodd" />
    <path d="m0.3073336 3.142621l173.79529 0l0 168.47244l-173.79529 0z" fill-rule="evenodd" />
    <path stroke="#274e13" stroke-width="2.0" stroke-linejoin="round" stroke-linecap="butt"
        d="m0.3073336 3.142621l173.79529 0l0 168.47244l-173.79529 0z" fill-rule="evenodd" />
    <path fill="#b6d7a8"
        d="m9.989728 106.39991l0 0c0 -26.236343 29.021557 -49.14996 70.55905 -55.709045l0 10.100296l23.519684 -27.246616l-23.519684 -23.592594l0 10.100293l0 0c-41.5375 6.559086 -70.55905 29.472702 -70.55905 55.70904z"
        fill-rule="evenodd" />
    <path fill="#91ab86"
        d="m104.06847 133.29735l0 0c-42.311203 0 -79.417114 -17.274422 -90.682724 -42.21675l0 0c-7.8214154 17.31681 -1.9678736 35.874 15.824892 50.168884c17.792768 14.294876 45.48227 22.686478 74.85783 22.686478z"
        fill-rule="evenodd" />
    <path fill="#ffffff" fill-opacity="0.0"
        d="m9.989728 106.39991l0 0c0 -26.236343 29.021557 -49.14996 70.55905 -55.709045l0 10.100296l23.519684 -27.246616l-23.519684 -23.592594l0 10.100293l0 0c-41.5375 6.559086 -70.55905 29.472702 -70.55905 55.70904l0 30.638626l0 0c0 31.776283 42.120487 57.53605 94.078735 57.53605l0 -30.63861l0 0c-42.311203 0 -79.417114 -17.274422 -90.682724 -42.21675"
        fill-rule="evenodd" />
    <path stroke="#274e13" stroke-width="2.0" stroke-linejoin="round" stroke-linecap="butt"
        d="m9.989728 106.39991l0 0c0 -26.236343 29.021557 -49.14996 70.55905 -55.709045l0 10.100296l23.519684 -27.246616l-23.519684 -23.592594l0 10.100293l0 0c-41.5375 6.559086 -70.55905 29.472702 -70.55905 55.70904l0 30.638626l0 0c0 31.776283 42.120487 57.53605 94.078735 57.53605l0 -30.63861l0 0c-42.311203 0 -79.417114 -17.274422 -90.682724 -42.21675"
        fill-rule="evenodd" />
    <path fill="#b6d7a8"
        d="m107.111725 38.711784l28.503937 -24.518517l28.503937 24.518517l-14.251968 0l0 125.24526l-28.503937 0l0 -125.24526z"
        fill-rule="evenodd" />
    <path stroke="#274e13" stroke-width="2.0" stroke-linejoin="round" stroke-linecap="butt"
        d="m107.111725 38.711784l28.503937 -24.518517l28.503937 24.518517l-14.251968 0l0 125.24526l-28.503937 0l0 -125.24526z"
        fill-rule="evenodd" />
</g>
<g id="close"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></g>
<g id="perm-scan-wifi"><path d="M12 3C6.95 3 3.15 4.85 0 7.23L12 22 24 7.25C20.85 4.87 17.05 3 12 3zm1 13h-2v-6h2v6zm-2-8V6h2v2h-2z"></path></g>
<g id="error"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></g>
<g id="expand-less"><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/></g>
<g id="expand-more"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/></g>
<g id="arrow-back"><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path></g>
<g id="supervisor-account"><path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z"></path></g>
<g id="users" viewBox="0 0 24 24"><path d="M5.3 9.7c-0.4 0-0.9-0.2-0.9-0.6s0.5-0.3 0.9-1c0 0 1.8-4.9-1.8-4.9s-1.8 4.9-1.8 4.9c0.4 0.7 0.9 0.6 0.9 1s-0.5 0.6-0.9 0.6c-0.6 0.1-1.1 0-1.7 0.6v5.7h5c0.2-1.7 0.7-5.2 1.1-6.1 0 0 0.1-0.1 0.1-0.1-0.2-0.1-0.5-0.1-0.9-0.1z"/><path d="M16 9.5c-0.7-0.8-1.3-0.7-2-0.8-0.5-0.1-1.1-0.2-1.1-0.7s0.6-0.3 1.1-1.2c0 0 2.1-5.9-2.2-5.9-4.4 0.1-2.3 6-2.3 6 0.5 0.8 1.1 0.7 1.1 1.1 0 0.5-0.6 0.6-1.1 0.7-0.9 0.1-1.7 0-2.5 1.5-0.4 0.9-1 5.8-1 5.8h10v-6.5z"/></g>
</defs></svg>
</iron-iconset-svg>`;

document.head.appendChild(documentContainer.content);
